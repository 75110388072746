import firebase from 'firebase';
 var config = {
  apiKey: "AIzaSyCGUa7HocLU_u4nZwYt71idVHg4J1VHUw8",
  authDomain: "my-first-firebase-projec-d3f0a.firebaseapp.com",
  databaseURL: "https://my-first-firebase-projec-d3f0a.firebaseio.com",
  projectId: "my-first-firebase-projec-d3f0a",
  storageBucket: "my-first-firebase-projec-d3f0a.appspot.com",
  messagingSenderId: "51761994810"
};

export default firebase.initializeApp(config);