const ITEMS = [
  { name: "t", label: "☕️ Tea" },
  { name: "c", label: "☕️ Coffee" },
  { name: "m", label: "🥛 Milk" },
  { name: "b", label: "🍪 Biscuits" },
  { name: "bo", label: "🥘 Boondhi" },
  { name: "k", label: "🍟 Kara Sev" }
];

const THEME_TEXT = {
  dark: "Light Mode",
  light: "Dark Mode"
};

var ITEMS_INITIAL_COUNT = {};

ITEMS.reduce(function(map, item) {
  ITEMS_INITIAL_COUNT[item.name] = 0;
  return ITEMS_INITIAL_COUNT;
}, {});

export { ITEMS, ITEMS_INITIAL_COUNT, THEME_TEXT };
