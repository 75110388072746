import React from 'react';

export default class TotalCountBlock extends React.Component {
  render() {
    let { item } = this.props;
    return (
      <div className="total-block">
        <p>{item.label}</p>
        <h3>{this.props.count}</h3>
      </div>
    );
  }
}
