import firebase from "firebase";
import Firebase from "../Config/Firebase";
var Auth = Firebase.auth();

var authenticate = function(options) {
  let { onSuccess } = options;
  firebase.auth().onAuthStateChanged(function(currentUser) {
    if (currentUser) {
      var user = currentUser.toJSON();
      onSuccess({ currentUser: user });
    } else {
      onSuccess({ currentUser: null });
    }
  });
};

var signIn = function(options) {
  let { onSuccess, onError } = options;

  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");

  Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function() {
      Auth.signInWithPopup(provider)
        .then(function(result) {
          var currentUser = result.user.toJSON();
          onSuccess({
            currentUser: currentUser
          });
        })
        .catch(function(error) {
          var errorMessage = error.message;
          onError({ error: errorMessage });
        });
    })
    .catch(function(error) {
      onError({ error: error });
    });
};

var signOut = function(options) {
  let { onSuccess, onError } = options;

  firebase
    .auth()
    .signOut()
    .then(
      function() {
        onSuccess();
      },
      function(error) {
        onError({ error: error });
      }
    );
};

export { authenticate, signIn, signOut };
