import React from "react";
import { authenticate, signIn, signOut } from "../Models/Auth";
import {
  incrementItem,
  resetItem,
  fetchData,
  calculateTotal,
  getTheme,
  setTheme
} from "../Models/TCM";
import Loader from "./Loader/Loader";
import moment from "moment";
import UserOrderBlock from "./UserOrderBlock";
import TotalCountBlock from "./TotalCountBlock";
import ItemButtonBlock from "./ItemButtonBlock";

import { ITEMS, ITEMS_INITIAL_COUNT, THEME_TEXT } from "../Config/Variables";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      authLoading: true,
      userList: [],
      count: ITEMS_INITIAL_COUNT,
      userCount: ITEMS_INITIAL_COUNT,
      todayDate: moment().format("LL A"),
      theme: getTheme()
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  authenticate() {
    authenticate({
      onSuccess: function(data) {
        let { currentUser } = data;
        this.setState({
          currentUser: currentUser,
          authLoading: false
        });
        if (currentUser) {
          this.fetchData();
        }
      }.bind(this),
      onError: function(data) {
        this.setState({
          authLoading: false
        });
      }.bind(this)
    });
  }

  handleSignIn() {
    signIn({
      onSuccess: function(data) {
        let { currentUser } = data;
        this.setState({
          currentUser: currentUser,
          authLoading: false
        });
      }.bind(this),
      onError: function(data) {
        this.setState({
          authLoading: false
        });
      }.bind(this)
    });
  }

  handleSignOut() {
    signOut({
      onSuccess: function(data) {
        this.setState({
          currentUser: null,
          authLoading: false
        });
      }.bind(this),
      onError: function(data) {
        this.setState({
          authLoading: false
        });
      }.bind(this)
    });
  }

  incrementItem(item) {
    incrementItem({
      data: {
        currentUser: this.state.currentUser,
        item: item.name
      },
      onSuccess: function(data) {},
      onError: function(data) {}
    });
  }

  resetItem(item) {
    resetItem({
      data: {
        currentUser: this.state.currentUser,
        item: item.name
      },
      onSuccess: function(data) {},
      onError: function(data) {}
    });
  }

  fetchData() {
    fetchData({
      data: {
        currentUser: this.state.currentUser
      },
      onUserCount: function(data) {
        var userCount = Object.assign(this.state.userCount, data.userCount);
        this.setState({
          userCount: userCount
        });
      }.bind(this),
      onUpdate: function(data) {
        this.setState({
          userList: data.userList,
          count: calculateTotal(data.userList)
        });
      }.bind(this)
    });
  }

  hasZeros(data) {
    var filteredArray = Object.values(data).filter(function(x) {
      return x > 0;
    });

    return filteredArray.length === 0;
  }

  toggleTheme = () => {
    var theme;
    if (this.state.theme === "light") {
      theme = "dark";
    } else {
      theme = "light";
    }
    setTheme(theme);
    this.setState({
      theme: theme
    });
  };

  renderUserControlBlock() {
    if (this.state.authLoading) {
      return <Loader />;
    }
    if (Boolean(this.state.currentUser)) {
      return this.renderSelector();
    } else {
      return this.renderAuthBlock();
    }
  }

  renderAuthBlock() {
    return (
      <button className="AuthButton" onClick={this.handleSignIn.bind(this)}>
        Login With Google
      </button>
    );
  }

  renderItemBlock(item, index) {
    return (
      <ItemButtonBlock
        key={index}
        onIncrementClick={this.incrementItem.bind(this, item)}
        onResetClick={this.resetItem.bind(this, item)}
        item={item}
        count={this.state.userCount[item.name]}
      />
    );
  }

  renderResult(item, index) {
    return <TotalCountBlock count={this.state.count[item.name]} item={item} key={index} />;
  }

  renderUserOrder(user, index) {
    if (this.hasZeros(user.orders)) {
      return;
    }
    return <UserOrderBlock user={user} key={index} />;
  }

  renderSelector() {
    return (
      <div className="full-wrapper">
        <div className="left-wrapper ">
          <div className="total-wrapper">
            <h5 className="total-text">Total:</h5>
            <div className="Total-Stats-List">{ITEMS.map(this.renderResult.bind(this))}</div>
          </div>
          <div className="border" />
          <div className="">
            <h4>What you want today, {this.state.currentUser.displayName}?</h4>
            <div className="User-Button-List">{ITEMS.map(this.renderItemBlock.bind(this))}</div>
          </div>
        </div>

        <div className="border" />

        <div className="">
          <h4 className="others-order">What did others order?</h4>
          <ul className="Order-List">{this.state.userList.map(this.renderUserOrder.bind(this))}</ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={this.state.theme + "-mode"}>
        <div className="medium-container">
          <h1>Guys, Tea Coffee?</h1>
          <p className="date">{this.state.todayDate}</p>
          <div className="signoutbutton">
            <button onClick={this.toggleTheme}>{THEME_TEXT[this.state.theme]}</button>
            <button onClick={this.handleSignOut.bind(this)}>Sign out</button>
          </div>
          {this.renderUserControlBlock()}
        </div>
      </div>
    );
  }
}
