import Firebase from '../Config/Firebase';
import moment from 'moment';
import { ITEMS } from '../Config/Variables';

var RootRef = 
process.env.NODE_ENV === 'production' ?
    Firebase.database().ref('production') :
    Firebase.database().ref('development');

console.log(process.env.NODE_ENV);

var getDomainRef = function(currentUser) {
  var domain = currentUser.email.substring(currentUser.email.lastIndexOf("@") +1);
  var domainKey = domain.replace('.', '_');
  var todaysDate = moment().format('DD-MM-YY-A');
  domainKey = domainKey === "understandbetter_co" ? "skcript_com" : domainKey;
  return RootRef.child('TCM').child(domainKey).child(todaysDate)
}

var incrementItem = function(options) {
  let { data, onError } = options;
  let { currentUser } = data;
  var userData = {
    displayName: currentUser.displayName,
    email: currentUser.email,
    photoURL: currentUser.photoURL,
  };
  var ref = getDomainRef(currentUser).child(currentUser.uid);
  
  ref.child('orders').child(data.item).once('value').then(function(snapshot) {
    var count = Number(snapshot.val());
    var newData = {
      information: userData,
    };
    newData['orders/' + data.item] = count + 1;
    ref.update(newData);
  }).catch(function(error) {
    onError({error: error});
  });
}

var resetItem = function(options) {
  let { data, onError } = options;
  let { currentUser } = data;
  var ref = getDomainRef(currentUser).child(currentUser.uid).child('orders').child(data.item);
  
  ref.once('value').then(function(snapshot) {
    var newCount = 0;
    ref.set(newCount);
  }).catch(function(error) {
    onError({error: error});
  });
}

var fetchData = function(options) {
  let { data, onUserCount, onUpdate } = options;
  let { currentUser } = data;
  getDomainRef(currentUser).child(currentUser.uid).child('orders').on('value', function(snapshot){
    var userCount = snapshot.val();
    onUserCount({userCount: userCount});
  });

  getDomainRef(currentUser).orderByChild('c').on('value', function(snapshot){
    var newData = snapshot.val() || {};
    newData = Object.values(newData);
    onUpdate({userList: newData});
  });
}

var calculateTotal = function(data) {
  var result = {}, itemData = {};
  for (var i = data.length - 1; i >= 0; i--) {

    if (!data[i].orders) {
      result = {t: 0, c: 0, m: 0};
      continue;
    }
    for (var j = ITEMS.length - 1; j >= 0; j--) {
      itemData[ITEMS[j].name] = itemData[ITEMS[j].name] ? itemData[ITEMS[j].name] : 0;
      itemData[ITEMS[j].name] += parseInt(data[i].orders[ITEMS[j].name], 10) || 0;
      result[ITEMS[j].name] = itemData[ITEMS[j].name];
    }
  }
  return result;
}


var getTheme = function() {
  return localStorage.getItem('theme') || 'dark'
}

var setTheme = function(value) {
  return localStorage.setItem('theme', value)
}


export {
  incrementItem,
  resetItem,
  fetchData,
  calculateTotal,
  getTheme,
  setTheme
};
