import React from "react";

import { ITEMS } from "../Config/Variables";

export default class UserOrderBlock extends React.Component {
  renderCount(item, index) {
    return (
      <p key={index}>
        {item.label}: <b>{this.props.user.orders[item.name]}</b>
      </p>
    );
  }

  render() {
    let { user } = this.props;
    return (
      <li className="Order-Single">
        <img
          className="Order-User-Thumbnail"
          src={user.information.photoURL}
          alt={user.information.displayName}
        />
        <div className="Order-Info">
          <h3>{user.information.displayName}</h3>
          <div className="Order-Stats">{ITEMS.map(this.renderCount.bind(this))}</div>
        </div>
      </li>
    );
  }
}
