import React from 'react';

export default class ItemButtonBlock extends React.Component {
  render() {
    let { item } = this.props;
    return (
      <div className="User-Button-Block">
        <button 
          onClick={this.props.onIncrementClick}>
          {item.label} : {this.props.count}
        </button>
        <button className="round-button muted-button"
          onClick={this.props.onResetClick}>
          <i className="ion-refresh" />
        </button>
      </div>
    );
  }
}
